<template>
    <div class="h-screen">
        <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center h-full" style="background-color: rgb(239, 243, 248);">
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-5" v-if="working">
                <div class="text-center mb-5">
                    <div class="text-900 text-3xl font-medium mb-3">Verificando tu email, por favor espera...</div>
                    <ProgressSpinner />
                </div>
            </div>
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-5" v-if="!working">
                <div class="text-center mb-2">
                    <div class="text-900 text-3xl font-medium mb-5">{{ result }}</div>
                    <Button label="Entrar" icon="pi pi-sign-in" @click="goHome" v-if="!error"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/service/AuthService';
import ErrorReportService from '@/service/ErrorReportService.js';

export default {
    data() {
        return {
            working: true,
            result: '',
            error: false
        };
    },
    authService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.errorReportService = new ErrorReportService();

        if (!this.$route.params.userId || !this.$route.params.hash) {
            this.$watch(
                () => this.$route.params,
                () => {
                    this.verificate();
                },
            )
        } else {
            this.verificate();
        }
    },
    methods: {
        verificate() {
            this.authService.verifyEmail(this.$route.params.userId, this.$route.params.hash, document.location.search)
                .then(response => {
                    if (response.data.verified) {
                        window.sessionStorage.removeItem('afterLogin');
                        this.result = 'Hemos verificado tu email, ya puedes acceder a tu comunidad.';
                    } else {
                        this.error = true;
                        this.result = 'No hemos podido verificar tu email. Por favor, contacta con el administrador de tu comunidad.';
                    }

                    this.working = false;
                })
                .catch(error => {
                    console.error(error);

                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );

                    if (error.response.status === 401) {
                        window.sessionStorage.setItem('afterLogin', `/email/verify/${this.$route.params.userId}/${this.$route.params.hash}${document.location.search}`);
                        this.$router.replace({ name: 'login' });
                    } else {
                        this.error = true;
                        this.result = 'No hemos podido verificar tu email. Por favor, contacta con el administrador de tu comunidad.';
                        this.working = false;
                    }
                });
        },
        goHome() {
            this.$router.replace({ name: 'home' });
        }
    }
}
</script>